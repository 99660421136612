<template>
  <section id="subscriptions-types">
    <b-row class="mb-5">
      <b-col
        md="9"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t("subscriptions.title") }}</h1>
        <h5 class="text-primary">
          {{ $t("subscriptions.subtitle") }}
        </h5>
      </b-col>
      <b-col
        md="3"
        class="text-right"
        align-self="end"
      >
        <b-button
          variant="primary"
          @click="createSubscription()"
        >
          <span class="text-nowrap">{{ $t("subscriptions.create") }}</span>
        </b-button>
      </b-col>
    </b-row>
    <b-tabs
      v-model="tabSelected"
      lazy
    >
      <b-tab>
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span> {{ $t("dataGeneric.internal") }}</span>
        </template>
        <subscriptions-list
          :tab="tabSelected"
          :msg="false"
          class="mt-2"
        />
      </b-tab>

      <b-tab active>
        <template #title>
          <feather-icon icon="ToolIcon" />
          <span>{{ $t("dataGeneric.external") }}</span>
        </template>
        <subscriptions-list
          :tab="tabSelected"
          :msg="true"
          class="mt-2"
        />
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>

import {
  BTabs, BTab, BRow, BCol, BButton,
} from 'bootstrap-vue'
import SubscriptionsList from './SubscriptionsList.vue'

export default {
  components: {
    BTabs,
    BTab,
    SubscriptionsList,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      tabSelected: 0,
    }
  },
  methods: {
    createSubscription() {
      this.$router.push({ name: 'subscriptions-create' })
    },
  },
}
</script>
<style lang="scss" scoped>
#subscriptions-types .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#subscriptions-types .card-body h4 {
  font-size: 1.286rem !important;
}
</style>
