<template>
  <section id="paySubscription">
    <b-row class="mt-2">
      <b-col>
        <b-form-input
          v-model="searchUser"
          autocomplete="new-password"
          :placeholder="$t('subscriptions.search')"
          @change="searchData"
        />
      </b-col>
      <b-col>
        <b-form-select
          v-model="payTypeSelected"
          :options="optionsPayType"
          @change="searchData"
        />
      </b-col>
      <b-col>
        <b-form-select
          v-model="statusSelected"
          :options="optionsStatus"
          @change="searchData"
        />
      </b-col>
      <b-col>
        <b-form-select
          v-model="subTypeSelected"
          :options="optionSuscriptions"
          @change="searchData"
        />
      </b-col>
      <b-col>
        <b-dropdown
          right
          variant="primary"
          class="mr-1"
        >
          <template #button-content>
            <feather-icon
              icon="FileTextIcon"
              class="mr-50"
              size="20"
            />
            {{ $t("purchases.export") }}
          </template>
          <b-dropdown-item @click="exportTable('CSV')">
            CSV
          </b-dropdown-item>
          <b-dropdown-item @click="exportTable('XLS')">
            XLS
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col style="text-align: end;">
        <b-button
          variant="success"
          @click="$refs['modal'].show()"
        >
          <span class="text-nowrap">

            <feather-icon
              icon="FilePlusIcon"
              size="18"
            />
            {{ $t('createPaidSubs') }}
          </span>
        </b-button>
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :show="isBusy"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-table
        class="tablePagos mt-2"
        responsive
        :no-border-collapse="true"
        :selectable="isSuperuser"
        :sticky-header="true"
        :per-page="perPage"
        :busy="isBusy"
        :items="suscriptionList"
        :fields="tableColumns"
        select-mode="single"
        @row-selected="onRowSelected"
      >
        <template #table-busy>
          <div
            v-if="!exporting"
            class="text-center text-danger my-2"
          >
            <b-spinner class="align-middle mr-1" />
            <strong>{{ $t('load') }}...</strong>
          </div>
          <div
            v-else
            class="text-center my-2"
          >
            <div class="text-center">
              <feather-icon
                icon="ClockIcon"
                size="30"
              />
              <p id="cancel-label">
                {{ $t('exporting') }}
              </p>
            </div>
          </div>
        </template>
        <template #cell(user)="data">
          <p>{{ cleanUsername(data.item.node.user.username) }}</p>
        </template>
        <template #cell(payType)="data">
          <p>{{ optionsPayType[data.item.node.payType] }}</p>
        </template>
        <template #cell(status)="data">
          <p>{{ optionsStatus[data.item.node.status] }}</p>
        </template>
        <template #cell(price)="data">
          <p>{{ data.item.node.price }}</p>
        </template>
        <template #cell(subscription)="data">
          <p>{{ data.item.node.subscription.name }}</p>
        </template>
        <template #cell(createdAt)="data">
          <p>{{ limpiarFecha(data.item.node.createdAt) }}</p>
        </template>
        <template #cell(expirationDate)="data">
          <p>{{ limpiarFecha(data.item.node.expirationDate) }}</p>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0"
              dropleft
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="17"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click.prevent="onRowSelected([...data.item], 'edit')">
                <div class="d-flex">
                  <feather-icon
                    icon="Edit2Icon"
                    class=" mr-2"
                    size="18"
                  />
                  {{ $t('dataGeneric.edit') }}
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="isSuperuser"
                @click.prevent="onRowSelected([...data.item], 'delete')"
              >
                <div class="d-flex">
                  <feather-icon
                    icon="Trash2Icon"
                    class=" mr-2"
                    size="18"
                  />
                  {{ $t('dataGeneric.delete') }}
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-if="rows > perPage"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="itemList"
        size="lg"
        @input="fetchData()"
      />
    </b-overlay>

    <b-modal
      ref="modal"
      centered
      size="lg"
      :title="isStripe ? $t('paidSusExt') : $t('paidSusInt')"
      @hidden="resetModal"
      @ok="validationForm"
    >
      <validation-observer
        ref="paidContent"
        tag="form"
      >
        <b-overlay
          :show="show"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
          variant="transparent"
        >
          <b-row>
            <b-col>
              <b-form-group :label="$t('methodPayment')">
                <b-form-select
                  v-model="payType"
                  :options="opPayType"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :label="$t('estado')">
                <b-form-select
                  v-model="status"
                  :options="statusOp"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="suscription"
                rules="required"
              >
                <b-form-group
                  :label="$t('domain.sus') + '*'"
                  label-for="suscription"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <div class="d-flex">
                    <b-form-input
                      id="suscription"
                      v-model="suscriptionName"
                      :placeholder="$t('common.el')"
                      :state="errors.length > 0 ? false : null"
                      maxlength="150"
                      required
                      disabled
                    />
                    <b-button
                      class="ml-2"
                      variant="primary"
                      @click="$refs.SuscriptionModal.show()"
                    >
                      {{ $t('select') }}
                    </b-button>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="User"
                rules="required"
              >
                <b-form-group
                  :label="$t('User') + '*'"
                  label-for="User"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <div class="d-flex">
                    <b-form-input
                      id="User"
                      v-model="userPaid"
                      :placeholder="$t('User')"
                      :state="errors.length > 0 ? false : null"
                      maxlength="150"
                      required
                      disabled
                    />
                    <b-button
                      class="ml-2"
                      variant="primary"
                      @click="$refs.userModal.show()"
                    >
                      {{ $t('select') }}
                    </b-button>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="exDate"
                rules="required"
              >
                <b-form-group
                  :label="$t('subscriptions.expiredAt') + '*'"
                  label-for="exDate"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-datepicker
                    id="exDate"
                    v-model="expirationDate"
                    :placeholder="$t('seleccionarFecha')"
                    class="mb-1"
                    locale="en"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col v-if="isStripe">
              <validation-provider
                v-slot="{ errors }"
                name="price"
                rules="required"
              >
                <b-form-group
                  :label="$t('purchases.price') + '*'"
                  label-for="price"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="price"
                    v-model="price"
                    type="number"
                    :placeholder="$t('purchases.price')"
                    :state="errors.length > 0 ? false : null"
                    maxlength="10"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('idSuscription')"
                label-for="idPaySubs"
                :invalid-feedback="$t('required')"
              >
                <b-form-input
                  id="idPaySubs"
                  v-model="idPaySubs"
                  :placeholder="$t('idSuscription')"
                  maxlength="150"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('nombreAppTV')"
                label-for="appTv"
                :invalid-feedback="$t('required')"
              >
                <b-form-input
                  id="appTv"
                  v-model="appTv"
                  :placeholder="$t('nombreAppTV')"
                  maxlength="150"
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('tokenPaid')"
                label-for="tokenPaid"
              >
                <b-form-textarea
                  id="tokenPaid"
                  v-model="tokenPaid"
                  :placeholder="$t('tokenPaid')"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('dataGeneric.activeQ')"
                label-for="isActiveSave"
              >
                <b-form-checkbox
                  id="isActiveSave"
                  v-model="isActiveSave"
                  switch
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-overlay>
      </validation-observer>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          {{ $t('dataGeneric.cancel') }}
        </b-button>
        <b-button
          variant="success"
          class="ml-1"
          @click="ok()"
        >
          {{ $t('dataGeneric.save') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      ref="SuscriptionModal"
      size="xl"
      hide-footer
      :title="$t('common.el')"
    >
      <div class="d-block text-center">
        <suscription-selector-modal
          :is-stripe="isStripe"
          @data="SelectSubscriptionId"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="$refs.SuscriptionModal.hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>

    <b-modal
      ref="userModal"
      size="xl"
      hide-footer
      :title="$t('shopOrder')"
    >
      <div class="d-block ">
        <final-user-selector @finalUser="getFinalUser" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs.userModal.hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import {
  BFormSelect,
  BFormInput,
  BPagination,
  BCol,
  BSpinner,
  BOverlay,
  BRow,
  BFormCheckbox,
  BButton,
  BFormTextarea,
  BDropdown,
  BFormDatepicker,
  BFormGroup,
  BDropdownItem,
  BTable,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  messageError, showToast, utf8ToB64, cleanUsername,
  isEmpty,
} from '@/store/functions'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import xlsExport from 'xlsexport'
import { getUserData } from '@/auth/utils'
import FinalUserSelector from '@/views/common/FinalUserSelector.vue'
import SuscriptionSelectorModal from '@/views/common/SuscriptionSelectorModal.vue'

export default {
  components: {
    BTable,
    FinalUserSelector,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BPagination,
    SuscriptionSelectorModal,
    BSpinner,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BDropdown,
    BOverlay,
    BFormDatepicker,
    BFormGroup,
    BDropdownItem,
    BCol,
    BRow,
    BButton,
  },
  props: {
    isStripe: Boolean,
    filterSuscription: Array,
  },
  data() {
    return {
      required,
      currentParentFolder: null,
      perPage: 15,
      currentPage: 1,
      idSuscription: null,
      userData: getUserData(),
      rows: null,
      response: null,
      suscriptionList: [],
      appTv: '',
      idPaySubs: '',
      status: 'PAI',
      isActiveSave: true,
      isBusy: false,
      userPaid: null,
      optionsPayType: {
        NULL: this.$t('subscriptions.type'),
        GOW: 'Google',
        APP: 'Apple',
        STR: 'Stripe',
        EPO: 'Epoch',
        AMZ: 'Amazon',
        MAN: this.$t('manualCreat'),
        COD: this.$t('Código'),
      },
      exporting: false,
      show: false,
      payType: 'MAN',
      userId: null,
      statusOp: {
        PAI: this.$t('pai'),
        UPA: this.$t('upa'),
        CAN: this.$t('can'),
        EXP: this.$t('exp'),
      },
      optionsStatus: {
        NULL: this.$t('subscriptions.status'),
        PAI: this.$t('subscriptions.Paid'),
        UPA: this.$t('subscriptions.Nopaid'),
        CAN: this.$t('subscriptions.Cancelled'),
        EXP: this.$t('subscriptions.Expired'),
      },
      expirationDate: null,
      opPayType: {
      },
      price: 0,
      tokenPaid: '',
      suscriptionId: null,
      suscriptionName: null,
      tableColumns: [],
      optionSuscriptions: [{ value: null, text: this.$t('subscriptions.filterSubs') }],
      searchUser: '',
      payTypeSelected: 'NULL',
      statusSelected: 'NULL',
      subTypeSelected: null,
      isSuperuser: true,
      dataToExport: [],
    }
  },
  watch: {
    filterSuscription() {
      this.fillOptSuscription()
    },
  },

  mounted() {
    if (!this.isStripe) {
      this.tableColumns = [
        { key: 'user', sortable: false, label: this.$t('User') },
        { key: 'payType', sortable: false, label: this.$t('purchases.payType') },
        { key: 'subscription', sortable: false, label: this.$t('Subscriptions') },
        { key: 'status', sortable: false, label: this.$t('code.status') },
        { key: 'createdAt', sortable: false, label: this.$t('subscriptions.createdAt') },
        { key: 'expirationDate', sortable: false, label: this.$t('subscriptions.expirationDate') },
      ]

      this.tableColumns = [
        ...this.tableColumns,
        { key: 'actions', label: this.$t('message.tableHeader.action') },
      ]
    } else {
      this.tableColumns = [
        { key: 'user', sortable: false, label: this.$t('User') },
        { key: 'payType', sortable: false, label: this.$t('purchases.payType') },
        { key: 'price', label: this.$t('purchases.price') },
        { key: 'subscription', sortable: false, label: this.$t('Subscriptions') },
        { key: 'status', sortable: false, label: this.$t('code.status') },
        { key: 'createdAt', sortable: false, label: this.$t('subscriptions.createdAt') },
        { key: 'expirationDate', sortable: false, label: this.$t('subscriptions.expirationDate') },
      ]
    }
    this.isSuperuser = this.userData.isSuperuser
    if (this.isSuperuser) {
      this.tableColumns = [
        ...this.tableColumns,
        { key: 'actions', label: this.$t('message.tableHeader.action') },
      ]
      this.opPayType = {
        GOW: 'Google',
        APP: 'Apple',
        STR: 'Stripe',
        EPO: 'Epoch',
        AMZ: 'Amazon',
        MAN: this.$t('manualCreat'),
        COD: this.$t('Código'),
      }
    } else {
      this.opPayType = {
        MAN: this.$t('manualCreat'),
      }
    }
    this.fetchData()
  },
  methods: {
    fillOptSuscription() {
      try {
        this.filterSuscription.forEach(item => {
          this.optionSuscriptions = [
            ...this.optionSuscriptions,
            { value: item.node.id, text: item.node.name },
          ]
        })
      } catch (error) {
        console.log(error)
      }
    },
    exportTable(type) {
      let clientId = ''
      try {
        // eslint-disable-next-line prefer-destructuring
        clientId = atob(this.userData.profile.client.id).split(':')[1]
        // eslint-disable-next-line no-empty
      } catch (error) { }
      this.dataToExport = []
      this.exporting = !this.exporting
      this.isBusy = !this.isBusy

      this.exportData('', type, clientId)
    },
    onRowSelected(data, action) {
      if (data[0]) {
        const {
          id, receipt, payType, expirationDate, subscription, user, idPaySubs, price, status, isActive,
        } = data[0].node
        const appTv = data[0].node.package
        this.idSuscription = id
        this.payType = payType
        this.status = status
        this.suscriptionName = subscription?.name
        this.suscriptionId = subscription?.id
        this.appTv = appTv
        this.userPaid = user?.username
        this.userId = user?.id
        this.price = price
        this.idPaySubs = idPaySubs
        this.tokenPaid = receipt
        this.isActiveSave = isActive
        this.expirationDate = expirationDate

        if (action === 'edit') {
          this.$refs.modal.show()
        } else if (action === 'delete') {
          this.$swal({
            title: this.$t('code.title'),
            text: this.$t('rem'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('code.confirm'),
            cancelButtonText: this.$t('dataGeneric.cancel'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              const query = `
            mutation {
              deletePaySuscription(id:${this.idSuscription}) {
                found
              }
            }
          `
              axios
                .post('', { query })
                .then(res => {
                  if (res.data.data.deletePaySuscription.found) {
                    showToast(this.$t('success'), 1, this)
                    this.fetchData()
                  }
                })
                .catch(() => {
                  showToast(this.$t('error'), 2, this)
                })
            }
          })
        }
      }
    },
    searchData() {
      this.currentPage = 1
      this.fetchData()
    },
    filterData(data) {
      let dataSubs = data
      console.log(data)
      if (this.searchUser !== null) {
        dataSubs = this.suscriptionList.filter(item => item.node.user.username.includes(this.searchUser))
      }

      if (this.payTypeSelected !== 'NULL') {
        dataSubs = dataSubs.filter(
          item => item.node.payType === this.payTypeSelected,
        )
      }

      if (this.statusSelected !== 'NULL') {
        dataSubs = dataSubs.filter(
          item => item.node.status === this.statusSelected,
        )
      }

      if (this.subTypeSelected !== null) {
        dataSubs = dataSubs.filter(
          item => item.node.subscription.id === this.subTypeSelected,
        )
      }

      return dataSubs
    },
    exportData(cursor = '', typeExport, clientId) {
      const query = `{
          allPaysubscription(            
            is_External:${this.isStripe},
            client_Id:"${clientId}",
            after:"${cursor}"
            search_User:"${this.searchUser}",
            ${this.payTypeSelected === 'NULL' ? '' : `payType:"${this.payTypeSelected}"`}
            ${this.statusSelected === 'NULL' ? '' : `status:"${this.statusSelected}"`}
            ${this.subTypeSelected === null ? '' : `subscription:"${utf8ToB64(`id: ${this.subTypeSelected}`)}"`}            
              ){
            totalCount 
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              node {
                id
                user{
                    id
                    username
                }
                payType
                idPaySubs
                status
                price       
                package
                receipt
                expirationDate
                isActive
                createdAt
                subscription {
                  id
                  name
                }
                
              }
            }
          }

          }`
      axios
        .post('', {
          query,
        })
        .then(response => {
          messageError(response, this)
          const info = response.data.data.allPaysubscription
          const page = info.pageInfo
          const paySubscriptions = info.edges
          const nodes = paySubscriptions.map(item => item.node)
          this.dataToExport = [...this.dataToExport, ...nodes]
          if (page.hasNextPage) {
            this.exportData(page.endCursor, typeExport, clientId)
          } else {
            const dataTable = this.dataToExport.map(item => {
              const commonData = {
                [this.$t('User')]: this.cleanUsername(item.user.username),
                [this.$t('purchases.payType')]: this.optionsPayType[item.payType],
                [this.$t('code.status')]: this.optionsStatus[item.status],
                [this.$t('Subscriptions')]: item.subscription.name,
                [this.$t('subscriptions.createdAt')]: this.limpiarFecha(item.createdAt),
                [this.$t('subscriptions.expiredAt')]: this.limpiarFecha(item.expirationDate),
              }
              return this.isStripe
                ? {
                  ...commonData,
                  [this.$t('purchases.price')]: item.price,
                }
                : commonData
            })
            const f = new Date()
            const m = f.getMonth() + 1
            const name = `${this.$t('Payments')}_${f.getFullYear()}${m < 10 ? (`0${m}`) : `${m}`}${f.getDate()}`

            if (!isEmpty(this.dataToExport)) {
              // eslint-disable-next-line new-cap
              const xls = new xlsExport(dataTable, this.$t('Payments'))
              if (typeExport === 'XLS') { xls.exportToXLS(`${name}.xls`) } else { xls.exportToCSV(`${name}.csv`) }
            }
            this.exporting = !this.exporting
            this.isBusy = !this.isBusy
          }
        }).catch(err => {
          console.log(err)
          showToast(this.$t('subtitles.translationError'), 0, this)
        })
    },

    getFinalUser(item) {
      const { id, username } = item
      this.userId = id
      this.userPaid = username
      this.$refs.userModal.hide()
    },
    validationForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      return new Promise(resolve => {
        this.$refs.paidContent.validate().then(success => {
          if (success) {
            resolve(true)
            this.handleOk()
          }
        })
      })
    },
    downloadCSVFile(csvData) {
      // Create CSV file object and feed
      // our csvData into it
      const CSVFile = new Blob([csvData], {
        type: 'text/csv',
      })

      // Create to temporary link to initiate
      // download process
      const tempLink = document.createElement('a')

      // Download csv file
      tempLink.download = this.$t('fileSubcriptionPayments')
      const url = window.URL.createObjectURL(CSVFile)
      tempLink.href = url

      // This link should not be displayed
      tempLink.style.display = 'none'
      document.body.appendChild(tempLink)

      // Automatically click the link to
      // trigger download
      tempLink.click()
      document.body.removeChild(tempLink)
    },
    resetModal() {
      this.payType = 'MAN'
      this.idSuscription = null
      this.status = 'PAI'
      this.contentPaid = null
      this.contentId = null
      this.suscriptionName = null
      this.suscriptionId = null
      this.userPaid = null
      this.userId = null
      this.idPayment = null
      this.price = 0
      this.idPaySubs = null
      this.tokenPaid = ''
      this.expirationDate = null
      this.appTv = null
      this.isActiveSave = true
    },
    handleOk() {
      this.show = true
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      let query = ''
      if (this.idSuscription) {
        query = `mutation(
        $id: ID!,
        $user: ID!,
        $payType: PaySubscriptionPayTypeInput,
        $subscription: ID,
        $price: Float,
        $idPaySubs: String!,
        $receipt: String!,
        $expirationDate: Date!,
        $package: String!,
        $isActive: Boolean,
        $status:PaySubscriptionStatusInput,
        ){
          updatePaySuscription(id:$id,input:{
            payType:$payType,
            user:$user,
            price:$price,
            idPaySubs:$idPaySubs,
            subscription:$subscription,
            expirationDate:$expirationDate,
            receipt:$receipt,
            package:$package,
            isActive:$isActive,
            status:$status,
          }){
              paySubscription{
              id
            }
          }
        }`
      } else {
        query = `mutation(
        $user: ID!,
        $payType: PaySubscriptionPayTypeInput,
        $subscription: ID,
        $price: Float,
        $idPaySubs: String!,
        $receipt: String!,
        $expirationDate: Date!,
        $package: String!,
        $isActive: Boolean,
        $status:PaySubscriptionStatusInput,
        ){
          createPaySuscription(input:{
            payType:$payType,
            user:$user,
            price:$price,
            idPaySubs:$idPaySubs,
            subscription:$subscription,
            expirationDate:$expirationDate,
            receipt:$receipt,
            package:$package,
            isActive:$isActive,
            status:$status,
          }){
              paySubscription{
              id
            }
          }
        }
          `
      }

      const variables = {

        id: this.idSuscription,
        user: this.userId,
        payType: this.payType,
        subscription: this.suscriptionId,
        price: this.price,
        status: this.status,
        receipt: this.tokenPaid,
        package: this.appTv,
        expirationDate: this.expirationDate,
        idPaySubs: this.idPaySubs,
        isActive: this.isActiveSave,
      }
      data.append('variables', JSON.stringify(variables))
      data.append('query', query)
      axios
        .post('', data, config)
        .then(res => {
          this.show = false
          messageError(res, this)
          showToast(this.$t('success'), 1, this)
          this.$refs.modal.hide()
          this.fetchData()
        }).catch(() => {
          this.show = false
          showToast(this.$t('error'), 0, this)
        })
    },
    SelectSubscriptionId(data) {
      const [suscriptionId, suscriptionName] = data
      this.suscriptionId = suscriptionId
      this.suscriptionName = suscriptionName

      this.$refs.SuscriptionModal.hide()
    },
    fetchData() {
      let clientId = ''
      try {
        // eslint-disable-next-line prefer-destructuring
        clientId = atob(this.userData.profile.client.id).split(':')[1]
        // eslint-disable-next-line no-empty
      } catch (error) { }

      this.isBusy = !this.isBusy
      const query = `{
          allPaysubscription(
            offset:${this.perPage * (this.currentPage - 1)},
            first:${this.perPage},
            is_External:${this.isStripe},
            client_Id:"${clientId}",
            search_User:"${this.searchUser}",
            ${this.payTypeSelected === 'NULL' ? '' : `payType:"${this.payTypeSelected}"`}
            ${this.statusSelected === 'NULL' ? '' : `status:"${this.statusSelected}"`}
            ${this.subTypeSelected === null ? '' : `subscription:"${utf8ToB64(`id: ${this.subTypeSelected}`)}"`}
            
              ){
            totalCount 
            edges {
              node {
                id
                user{
                    id
                    username
                }
                payType
                idPaySubs
                status
                price       
                package
                receipt
                expirationDate
                isActive
                createdAt
                subscription {
                  id
                  name
                }
                
              }
            }
          }

          }`
      axios
        .post('', {
          query,
        })
        .then(response => {
          messageError(response, this)
          const paySubscriptions = response.data.data.allPaysubscription
          this.rows = paySubscriptions.totalCount
          this.suscriptionList = paySubscriptions.edges

          this.isBusy = !this.isBusy
        }).catch(() => {
          this.isBusy = !this.isBusy
          showToast(this.$t('error'), 0, this)
        })
    },
    itemsForList() {
      return this.suscriptionList.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      )
    },
    limpiarFecha(fecha) {
      const subscriptionDate = new Date(fecha)
      const newDate = subscriptionDate.toLocaleDateString('en-GB')
      return newDate
    },
    cleanUsername,
  },
}
</script>

<style lang="scss">
#avatar {
  width: 10rem;
  height: 5.5rem;
}

.td {
  width: 50px;
  height: 100px;
}

.res {
  min-height: 150px;
  height: 300px;
  cursor: pointer;
}

.dropContainer {
  border: 3px dashed;
}

#paySubscription .card-width {
  width: 200px;
}

#paySubscription .list-group-item {
  transition: all 1s;
}

.tablePagos {
  height: 600px;
}

/* #div1 {
  overflow: scroll;
  height: 400px;
  *width: 500px;
} */
</style>
