<template>
  <section id="subscriptions-types">
    <b-overlay
      variant="transparent"
      :show="overlay"
    >
      <b-row>
        <!-- v-show="r.node.isStripeGestioned == msg" -->
        <b-col
          v-for="r in response"
          :key="r.node.id"
          class="mt-2"
          md="6"
          lg="4"
          xl="3"
          sm="12"
        >
          <b-card
            header-classes="bg-transparent"
            footer-classes="bg-transparent"
            body-classes="px-lg-7"
            class="border-0 text-center mb-2 card-custom-height"
            style="cursor: pointer;"
            @click="routeToEditView(r.node.id)"
          >
            <!--Header-->
            <h4 class="ls-1 text-white py-2 mb-1 mt-1 text-center">
              <span
                :class="r.node.isActive ? 'mr-50 bullet bullet-success bullet-sm' : 'mr-50 bullet bullet-danger bullet-sm'"
              />
              {{ r.node.name }}
            </h4>
            <!--Card Body-->
            <b-card-body>
              <div class="actions">
                <div class="action">
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreHorizontalIcon"
                        size="20"
                      />
                    </template>

                    <b-dropdown-item @click.stop.prevent="routeToEditView(r.node.id)">
                      <feather-icon
                        icon="Edit2Icon"
                        size="18"
                      />
                      {{ $t("subscriptions.edit") }}
                    </b-dropdown-item>
                    <b-dropdown-item @click.stop.prevent="deleteSubscription(r.node.id)">
                      <feather-icon
                        icon="DeleteIcon"
                        size="18"
                      />
                      {{ $t("subscriptions.delete") }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div
                v-if="tab == 1"
                class="display-4 text-white"
              >
                {{ r.node.price }}{{ currencyType[currency] }}
              </div>
              <span class="text-white"> {{ intervalOptions[r.node.interval] }}</span>
              <div class="mt-2 py-2">
                <span
                  v-if="r.node.description"
                  class="text-white"
                >{{
                  r.node.description
                }}</span>
                <span
                  v-else
                  class="text-sm text-muted"
                >
                  {{ $t("subscriptions.noexit") }}
                </span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col v-if="vacio">
          {{ $t("subscriptions.empty") }}
        </b-col>
      </b-row>
    </b-overlay>

    <b-row>
      <b-col>
        <h3
          v-if="msg"
          class="text-center"
        >
          {{ $t("purchases.paidExternal") }}
        </h3>
        <h3
          v-else
          class="text-center"
        >
          {{ $t("purchases.paidInternal") }}
        </h3>
        <pay-subscription
          :is-stripe="msg"
          :filter-suscription="response"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BOverlay,

} from 'bootstrap-vue'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import PaySubscription from './TablePay/PaySubscription.vue'

export default {
  components: {
    BCardBody,
    PaySubscription,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    BOverlay,

  },
  props: {
    msg: Boolean,
    tab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      response: null,
      userData: getUserData(),
      vacio: false,
      overlay: false,
      currency: null,
      currencyType: {
        EUR: '€',
        USD: '$',
        GBP: '£',
        ARS: 'Argentine peso',
        isInternal: false,
      },
      intervalOptions: {
        DAY: this.$t('code.Daily'),
        WEEK: this.$t('code.Weekly'),
        MONTH: this.$t('code.Monthly'),
        YEAR: this.$t('code.Annual'),
        LIFETIME: this.$t('code.Vitalicia'),
      },
    }
  },
  mounted() {
    this.getData()
    this.getPayMethod()
  },
  methods: {
    getData() {
      this.overlay = true
      axios
        .post('', {
          query: `
          query{
              allSubscription(client:"${this.userData.profile.client.id}",isStripeGestioned: ${this.msg}){
              edges{
                node{
                  id
                  name
                  interval
                  description
                  freeDays
                  price
                  maxDevices
                  country{
                    id
                    country
                    language {
                      id
                    }
                  }
                  isActive
                  isStripeGestioned                  
                }
              }  
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)
          this.response = res.data.data.allSubscription.edges
          this.vacio = this.response.length === 0
          this.overlay = false
        })
    },
    routeToView(subsid) {
      this.$router.push({ name: 'subscriptions-view', params: { id: subsid } })
    },
    routeToEditView(subsid) {
      this.$router.push({ name: 'subscriptions-edit', params: { id: subsid } })
    },
    deleteSubscription(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
          mutation{
            deleteSubscription(id:"${id}"){
              found
              deletedId
            }
          }
        `,
            })
            .then(r => {
              messageError(r, this)
              showToast(this.$t('code.delete'), 1, this)

              this.getData()
            })
            .catch(() => {
              showToast(this.$t('code.deleteErr'), 2, this)
            })
        }
      })
    },
    getPayMethod() {
      const { id } = this.userData.profile.client
      axios
        .post('', {
          query: `
              query{
                client(id:"${id}"){
                  externalPayMethod
                  currency
                }
            }
        `,
        })
        .then(res => {
          messageError(res, this)

          this.currency = res.data.data.client.currency
        })
        .catch(() => {
          this.currency = null
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.card-custom-height {
  height: 290px;
}

@media (min-width: 1080px) {
  .card-custom-height {
    height: 430px;
  }
}

@media (min-width: 1270px) {
  .card-custom-height {
    height: 340px;
  }
}

#subscriptions-types .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#subscriptions-types .card-body h4 {
  font-size: 1.286rem !important;
}

.actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
</style>
